import '../styles/globals.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'aos/dist/aos.css';
import type { AppProps } from 'next/app';
import { ReactElement, ReactNode, useEffect } from 'react';
import type { NextPage } from 'next';
import { LocaleProvider } from '@components/Contexts/LocaleContext';
import { TailwindBreakpointProvider } from '@components/Contexts/TailwindBreakpointContext';
// import NoSSR from 'react-no-ssr';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    localStorage.setItem('fromUrl', window.location.href);
  }, []);
  
  return (
    // I use NoSSR when I want to avoid hydration error while I develope on local
    // But it should not use on prod, because It will not generate any HTML content after build static file.
    // <NoSSR>
    <TailwindBreakpointProvider>
      <LocaleProvider>
        {getLayout(<Component {...pageProps} />)}
      </LocaleProvider>
    </TailwindBreakpointProvider>
    // </NoSSR>
  );
}
