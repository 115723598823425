import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import useBreakpoint from 'use-breakpoint';

const BREAKPOINTS = { default:0, md: 768, lg: 1024 }; // follow tailwind

interface TailwindBreakpointState {
  screen: string;
  screenWidth: number;
  screenHeight: number;
}

const TailwindBreakpointContext = createContext<TailwindBreakpointState>({
  screen: null,
  screenWidth: null,
  screenHeight: null,
});

export const TailwindBreakpointProvider: FC<any> = ({ children }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    const updateSize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  

  const value = useMemo<TailwindBreakpointState>(
    () => ({ 
      screen: breakpoint,
      screenWidth: width,
      screenHeight: height, 
    }),
    [breakpoint, width, height],
  );

  return (
    <TailwindBreakpointContext.Provider value={value}>
      {children}
    </TailwindBreakpointContext.Provider>
  );
};

export const useTailwindBreakpoint = () => {
  const context = useContext(TailwindBreakpointContext);
  return context;
};
