import {
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
} from 'react';

export type Lang = 'en' | 'th';

interface LocaleState {
  lang: Lang;
  setLang: (l: Lang) => void;
}

const LocaleContext = createContext<LocaleState>({
  lang: 'th',
  setLang: () => {},
});

export const LocaleProvider: FC<any> = ({ children }) => {
  const [lang, setLang_] = useState<Lang>('th');

  const setLang = (l: Lang) => {
    localStorage.setItem('lang', l);
    setLang_(l);
  };

  const value = useMemo<LocaleState>(() => ({ lang, setLang }), [lang]);

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
};

export const useLocale = () => {
  const context = useContext(LocaleContext);
  return context;
};
